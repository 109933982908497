import React from "react";
import styled from "styled-components";
import useMedia from "use-media";

import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Header from "./components/Header";
import Location from "./components/Location";
import OperatingHours from "./components/OperatingHours";
import SocialMediaModule from "./components/SocialMedia";
import { FirebaseConfig } from "./Firebase";
import HowItWorks from "./pages/HowItWorks";
import theme from "./resources/theme.json";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  background: ${theme.offwhite};
  font-family: ${theme.fonts.body};
  font-size: medium;
`;

const App = () => {
  const mobile = useMedia({ maxWidth: "768px" });

  return (
    <AppWrapper className="App">
      <FirebaseConfig />
      <Header isMobile={mobile} />
      <div style={{ width: mobile ? "100vw" : "768px" }}>
        <OperatingHours />
        <Location />
        <SocialMediaModule />
        <hr />
        <HowItWorks isMobile={mobile} />
        <Gallery />
      </div>
      <Footer />
    </AppWrapper>
  );
};

export default App;
