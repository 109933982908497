import { Disclaimer } from "../pages/HowItWorks";
import UTGOperatingHours from "../svg/utg-hours-2023.jpg"
import UTGMealPrep from "../svg/utg-meal-prep-2023.jpg";
import OrderNowButton from "./OrderNowButton";

const OperatingHours = () => {
  return (
    <div style={{ margin: "16px" }}>
      <Disclaimer renderComponent>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "16px"}}>
          <h1>Limited hours starting 12/8!</h1>
          <img style={{maxWidth: "100%", height: "auto"}} alt="Limited hours and limited menu starting Friday, 12/8." src={UTGOperatingHours} />
        </div>
      </Disclaimer>
      <Disclaimer renderComponent>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "16px"}}>
          <h1>UTG Holiday Pre-Orders</h1>
          <img style={{maxWidth: "100%", height: "auto"}} alt="UTG Holiday Pre-Orders" src={UTGMealPrep} />
          <br />
          <OrderNowButton />
        </div>
      </Disclaimer>
    </div>
  );
};

export default OperatingHours;
