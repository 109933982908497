import React from "react";
import styled from "styled-components";

import theme from "../resources/theme.json";

const CenteredText = styled.button`
  background: ${theme.maroon};
  padding: 16px;
  border: 4px solid ${theme.maroon};
  color: white;
  font-weight: 600;
  width: fit-content;
  text-decoration: none;
  font-family: ${theme.fonts.heading};
  cursor: pointer;
  font-size: 24px;
  border-radius: 45px;

  :hover,
  :active,
  :focus {
    background: ${theme.pink};
  }
`;

const OrderNowButton = () => {
  const handleOnClick = () => {
    setTimeout(() => {
      window.open("https://utgblono.square.site", "_blank");
    }, 200);
  };

  return (
    <CenteredText
      onClick={() => {
        handleOnClick();
      }}
    >
      {"Order Now".toUpperCase()}
    </CenteredText>
  );
};

export default OrderNowButton;
