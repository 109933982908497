import { Disclaimer } from "../pages/HowItWorks";

const Location = () => {
  return (
    <Disclaimer renderComponent style={{ margin: "16px" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ fontSize: "20px", margin: "16px" }}>Our location is 608 N. Main Street Bloomington, IL.</div>
        <iframe
          style={{ margin: "16px" }}
          title="UtG Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3034.6162017191327!2d-88.99631068485719!3d40.483755059484736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880b653523a4b101%3A0xcb9b87ec4c7910ad!2sUnder%20the%20Ground%20BloNo!5e0!3m2!1sen!2sus!4v1659992067774!5m2!1sen!2sus"
          width="300"
          height="300"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Disclaimer>
  );
};

export default Location;
